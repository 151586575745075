






































































import Vue from "vue";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import OtiumBasePage from "../OtiumBasePage.vue";
import DashboardArchivedProjectSummary from "./DashboardArchivedProjectSummary.vue";
import { ProjectsClient, CreateProjectModel, ProjectSummary, ProjectTagModel } from "@/api/OtiumAppApi";
import dayjs from "dayjs";

export default defineComponent({
  name: "ArchivedDashboard",
  components: {
    OtiumBasePage,
    DashboardArchivedProjectSummary
  },
  setup(props, { root }) {
    const projects = ref<Array<ProjectSummary>>([]);
    const client = new ProjectsClient();
    const tags = ref({} as { [key: string]: ProjectTagModel });
    const filterTag = ref(null as string | null);

    const sortedTags = computed(() => {
      const tagMap = tags.value;
      const sortedTags: ProjectTagModel[] = [];
      Object.keys(tagMap).forEach((key) => {
        sortedTags.push(tagMap[key]);
      });
      return sortedTags.sort((a, b) => a.tagName.localeCompare(b.tagName));
    });

    enum SortFields {
      StartDate,
      Status
    }

    const loadingProjects = ref(false);
    const fetchProjects = async () => {
      loadingProjects.value = true;
      projects.value = await client.archivedUserProjects();
      projects.value.forEach((project) =>
        project.tags.forEach((tag) => {
          const tagMap = tags.value;
          if (tagMap[tag.tagId] == undefined) Vue.set(tagMap, tag.tagId, tag);
        })
      );
      loadingProjects.value = false;
    };

    onMounted(async () => {
      await fetchProjects();
    });

    const searchTerm = ref("");
    const sortBy = ref(SortFields.StartDate);

    const filteredProjects = computed(() => {
      const filteredProjects = projects.value.filter(
        (project) =>
          project.name.toLocaleUpperCase().includes(searchTerm.value.toLocaleUpperCase()) &&
          (filterTag.value == null || project.tags.some((tag) => tag.tagId == filterTag.value))
      );
      switch (sortBy.value) {
        case SortFields.StartDate:
          filteredProjects.sort((a, b) => dayjs(a.createdOn).unix() - dayjs(b.createdOn).unix());
          break;
      }
      return filteredProjects;
    });

    const goToDashboard = () => {
      root.$router.push({ name: "Dashboard" });
    };

    return {
      projects,
      sortedTags,
      filterTag,
      searchTerm,
      sortBy,
      SortFields,
      filteredProjects,
      goToDashboard,
      loadingProjects
    };
  }
});
