


















































































import { defineComponent, ref, toRefs } from "@vue/composition-api";
import { ProjectsClient, ProjectSummary } from "@/api/OtiumAppApi";
import { useUserStore } from "@/stores/userStore";
export default defineComponent({
  name: "DashboardArchivedProjectSummary",
  props: {
    project: {
      type: ProjectSummary,
      required: true
    }
  },
  setup(props, { root }) {
    const userStore = useUserStore();
    const { project } = toRefs(props);

    const hovered = ref(false);

    const displayUnarchiveProjectDialog = ref(false);
    const unarchivingProject = ref(false);

    const isProjectSlotAvailable = ref(true);

    async function unarchiveProject() {
      const client = new ProjectsClient();

      unarchivingProject.value = true;

      const hasProjectAvailable = await client.checkActiveProjectAvailable(project.value.organizationId);

      if (hasProjectAvailable) {
        isProjectSlotAvailable.value = true;
        await client.unarchiveProject(project.value.id);
        unarchivingProject.value = false;
        displayUnarchiveProjectDialog.value = false;
        root.$router.push({ name: "Dashboard" });
      } else {
        isProjectSlotAvailable.value = false;
      }

      unarchivingProject.value = false;
    }

    return {
      userStore,
      hovered,
      displayUnarchiveProjectDialog,
      unarchivingProject,
      unarchiveProject,
      isProjectSlotAvailable
    };
  }
});
